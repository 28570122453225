html {
    background-color: $gray-200;
}

.app-container {
    min-height: auto;
}

.bg-info{
    background-color: #3a97d8!important;
}
